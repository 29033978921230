// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EpLp3iPPUw7b0_wdLB8B{padding-right:81px}.EpLp3iPPUw7b0_wdLB8B .JD0io0hNWtPcSTpasta4{align-items:center;display:flex;gap:15px;padding-bottom:12px}.EpLp3iPPUw7b0_wdLB8B .n_MXsIjbyWL0mgT0xGwg{width:400px}.EpLp3iPPUw7b0_wdLB8B .VpfeddgFISdr7C5ooYwx{padding-left:12px;width:388px}.EpLp3iPPUw7b0_wdLB8B .VpfeddgFISdr7C5ooYwx .hW6ug6EocTKWcc1YUBtZ{margin-bottom:12px}.EpLp3iPPUw7b0_wdLB8B .VpfeddgFISdr7C5ooYwx .hW6ug6EocTKWcc1YUBtZ::marker{color:var(--color-white)}.EpLp3iPPUw7b0_wdLB8B .xWRRCKSMcgoAeaUZC4bg{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px;margin-top:32px}.EpLp3iPPUw7b0_wdLB8B .zur9Axklh_tDEYWtDLUq{margin-bottom:16px}.EpLp3iPPUw7b0_wdLB8B .JRlGFCXPoitL5HQh2qr1{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-section/research-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  padding-right: 81px;\n\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    width: 400px;\n  }\n\n  & .list {\n    padding-left: 12px;\n    width: 388px;\n\n    & .listItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n    margin-top: 32px;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `EpLp3iPPUw7b0_wdLB8B`,
	"header": `JD0io0hNWtPcSTpasta4`,
	"description": `n_MXsIjbyWL0mgT0xGwg`,
	"list": `VpfeddgFISdr7C5ooYwx`,
	"listItem": `hW6ug6EocTKWcc1YUBtZ`,
	"divider": `xWRRCKSMcgoAeaUZC4bg`,
	"learnMore": `zur9Axklh_tDEYWtDLUq`,
	"linkText": `JRlGFCXPoitL5HQh2qr1`
};
export default ___CSS_LOADER_EXPORT___;
